import classnames from 'classnames';
import { useSetAtom } from 'jotai';
import React from 'react';
import contactModalTriggerButton from '../store/contactForm';
import styles from './AnimatedBallButton.module.scss';

type AnimatedBallButtonProps = {
  className?: string,
};
const AnimatedBallButton = ({ className }: AnimatedBallButtonProps) => {
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  return (
    <button
      type='button'
      className={classnames(styles.button, className)}
      onClick={(e) => {
        setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Homepage hero button`);
      }}
    >
      <div className={styles.content}>
        Start Project
        <svg className={styles.button__icon}>
          <use
            xlinkHref='/media/icon-nest.svg#iconNest'
            href='/media/icon-nest.svg#iconNest'
          />
        </svg>
      </div>
    </button>
  );
};

export default AnimatedBallButton;
