'use client';

import { MottoComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { useAtomValue } from 'jotai';
import React, { Fragment, useEffect, useState } from 'react';
import { MouseParallax } from 'react-just-parallax';
import AnimatedBallButton from '../../../components/AnimatedBallButton';
import { ANIMATED_TEXT_REGEX } from '../../../components/AnimatedTitle';
import Ellipse, { EllipseVariants } from '../../../components/Ellipse';
import FadeUpAnimatedText from '../../../components/FadeUpAnimatedText';
import activeHomeSection, { Section } from '../../../store/activeHomeSection';
import clientDeviceSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { Theme, theme } from '../../../store/theme';
import styles from './Hero.module.scss';

interface HeroProps {
  title: MottoComponent,
  className?: string,
  id: string,
}

type Title = Array<string | JSX.Element> | string | null;

function getFormattedMotto({ title, appTheme }: { title: MottoComponent, appTheme: Theme }): Title | null {
  const imageMarker = '{{titleImage}}';
  const categories = title.services.map(service => service.text);
  const motto = title.motto.replace(/(\r\n|\r|\n)/g, '<br>');
  let parsedTitle = parse(motto) as Array<string | JSX.Element> | string;
  if (Array.isArray(parsedTitle)) {
    return parsedTitle.map((element: string | JSX.Element, index: number) => {
      let isWithAnimation = null;
      if (typeof element === 'string') {
        isWithAnimation = element.match(ANIMATED_TEXT_REGEX);
      }

      if (typeof element === 'string' && element.indexOf(imageMarker) !== -1) {
        const titleImageFirstIndex = element.indexOf(imageMarker);
        const titleImageLastIndex = titleImageFirstIndex + imageMarker.length;
        return ( // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            {element.slice(0, titleImageFirstIndex)}
            <img
              src={`/media/Home/Hero/heroCircles_${appTheme}.webp`}
              alt='circlesImage'
              className={styles.title__image}
            />
            {element.slice(titleImageLastIndex)}
          </Fragment>
        );
      } else if (typeof element === 'string' && isWithAnimation) {
        const divider = isWithAnimation.index;
        const textFirstPart = element.slice(0, divider);
        const textLastPart = divider !== undefined && divider >= 0 ? element.slice(divider + isWithAnimation[0].length) : '';
        return ( // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            {textFirstPart}
            {/* <FadeUpAnimatedText text={isWithAnimation[0].slice(2, isWithAnimation[0].length - 2)} /> */}
            <FadeUpAnimatedText text={categories} />
            {textLastPart}
          </Fragment>
        );
      } else {
        return element;
      }
    });
  }

  return null;
}

const Hero = ({ title, className, id }: HeroProps) => {
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const [circleSize, setCircleSize] = useState<number>(600);
  const [formattedTitle, setFormattedTitle] = useState<Title>(getFormattedMotto({ title, appTheme }));
  const activeSection = useAtomValue(activeHomeSection);

  useEffect(() => {
    title && setFormattedTitle(getFormattedMotto({ title, appTheme }));
  }, [title, appTheme]);

  useEffect(() => {
    if (activeSection !== Section.FIRST) {
      setCircleSize(800);
    } else {
      setCircleSize(deviceSize === ScreenSize.DESKTOP_HUGE
        ? 960 : deviceSize === ScreenSize.TABLET_PORTRAIT
          ? 780 : deviceSize === ScreenSize.MOBILE ? 500 : 600);
    }
  }, [activeSection, deviceSize]);

  return (
    <section
      id={id}
      className={classnames(styles.hero, className)}
      style={{ background: appTheme === Theme.DARK ? 'url(/media/Home/Hero/hero-bg-dark.webp) left top / cover' : undefined }}
    >
      <div className={classnames(styles.images, {
        [styles.images_moved]: activeSection !== Section.FIRST,
      })}
      >
        <MouseParallax
          shouldPause={false}
          isAbsolutelyPositioned
          strength={0.2}
        >
          <Ellipse
            variant={EllipseVariants.NOISE}
            className={classnames(styles.ellipse, styles.ellipse_noise, {
              [styles.ellipse_noise_moved]: activeSection !== Section.FIRST,
            })}
            size={deviceSize === ScreenSize.DESKTOP_HUGE ? 148 : deviceSize === ScreenSize.MOBILE ? 79 : 100}
          />
        </MouseParallax>
        <MouseParallax
          shouldPause={false}
          isAbsolutelyPositioned
          strength={0.3}
        >
          <Ellipse
            variant={EllipseVariants.GRADIENT}
            className={classnames(styles.ellipse, styles.ellipse_gradient)}
            size={deviceSize === ScreenSize.DESKTOP_HUGE ? 64 : 40}
          />
        </MouseParallax>
        <MouseParallax
          shouldPause={false}
          isAbsolutelyPositioned
          strength={0.1}
        >
          <Ellipse
            variant={EllipseVariants.BORDER}
            size={deviceSize === ScreenSize.DESKTOP_HUGE ? 40 : 24}
            className={classnames(styles.ellipse, styles.ellipse_border)}
          />
        </MouseParallax>
        <MouseParallax
          shouldPause={false}
          isAbsolutelyPositioned
          strength={0.04}
        >
          <Ellipse
            variant={EllipseVariants.DRAW}
            size={circleSize}
            className={classnames(styles.ellipse, styles.ellipse_draw, {
              [styles.ellipse_draw_moved]: activeSection !== Section.FIRST,
            })}
          />
        </MouseParallax>
      </div>
      <h1 className={classnames(styles.title, {
        [styles.visible]: activeSection === Section.FIRST,
      })}
      >
        <AnimatedBallButton
          className={styles.ctaButton}
        />
        {formattedTitle}
      </h1>
    </section>
  );
};

export default Hero;
