'use client';

import classnames from 'classnames';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useAtomValue, useAtom } from 'jotai';
import styles from './Home.module.scss';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import { useSectionOffsetScroll } from '../../useSectionOffsetScroll';
import activeHomeSection, { prevActiveHomeSection, Section } from '../../store/activeHomeSection';

// type HomepageSidebarProps = {
//   consultationFormRef: React.RefObject<HTMLFormElement>,
//   firstSection: React.RefObject<HTMLElement>,
//   secondSection: React.RefObject<HTMLElement>,
//   thirdSection: React.RefObject<HTMLElement>,
//   fourthSection: React.RefObject<HTMLElement>,
//   techStackContentRef: React.RefObject<HTMLDivElement>,
// };

function HomepageSidebar() {
  const [activeSection, setActiveSection] = useAtom(activeHomeSection);
  const [prevActiveSection, setPrevActiveSection] = useAtom(prevActiveHomeSection);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const deviceSize = useAtomValue(deviceScreenSize);
  const dotStartPosition = useMemo(() => (deviceSize === ScreenSize.TABLET_LANDSCAPE ? 80 : 124), [deviceSize]);
  const [sidebarDotPosition, setSidebarDotPosition] = useState('calc(100vh - 100px)');

  // const firstSectionOffset = useSectionOffsetScroll(firstSection);
  // const secondSectionOffset = useSectionOffsetScroll(secondSection);
  // const thirdSectionOffset = useSectionOffsetScroll(thirdSection);
  // const fourthSectionOffset = useSectionOffsetScroll(fourthSection);

  const firstSectionOffset = useSectionOffsetScroll('firstSection');
  const secondSectionOffset = useSectionOffsetScroll('secondSection');
  const thirdSectionOffset = useSectionOffsetScroll('thirdSection');
  const fourthSectionOffset = useSectionOffsetScroll('fourthSection');

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const isMobile = windowWidth < 768;
    const firstSectionHeight = document.getElementById('firstSection')?.offsetHeight || 0;
    const isSecondSectionVisible = isMobile ? secondSectionOffset > 500 : secondSectionOffset - firstSectionHeight > 50;
    if (fourthSectionOffset > (document?.getElementById('consultationFormId')?.offsetTop || 300)) {
      if (activeSection !== Section.FOURTH) {
        setActiveSection(prev => {
          setPrevActiveSection(prev);
          return Section.FOURTH;
        });
      }
    } else if (thirdSectionOffset > (document?.getElementById('techStackContentId')?.offsetTop || 300)) {
      if (activeSection !== Section.THIRD) {
        setActiveSection(prev => {
          setPrevActiveSection(prev);
          return Section.THIRD;
        });
      }
    } else if (isSecondSectionVisible) {
      if (activeSection !== Section.SECOND) {
        setActiveSection(prev => {
          setPrevActiveSection(prev);
          return Section.SECOND;
        });
      }
    } else if (activeSection !== Section.FIRST) {
      setActiveSection(prev => {
        setPrevActiveSection(prev);
        return Section.FIRST;
      });
    }
  }, [firstSectionOffset, secondSectionOffset, thirdSectionOffset, fourthSectionOffset]);

  // useEffect(() => {
  //   let timeout: ReturnType<typeof setTimeout>;
  //   if (fourthSectionOffset > (consultationFormRef.current?.offsetTop || 300)) {
  //     setSidebarDotPosition(
  //       `calc(${(consultationFormRef.current?.offsetTop || 0)
  //       + (fourthSection.current?.offsetTop || 0) + (consultationFormRef.current?.offsetHeight || 0)
  //       + 2}px`,
  //     );
  //   } else if (thirdSectionOffset > (techStackContentRef.current?.offsetTop || 300)) {
  //     setSidebarDotPosition(`calc(${(techStackContentRef.current?.offsetTop || 0) + (thirdSection.current?.offsetTop || 0)}px`);
  //   } else if (secondSectionOffset > 300) {
  //     if (prevActiveSection && prevActiveSection < activeSection) {
  //       setSidebarDotPosition('calc(100vh)');
  //       timeout = setTimeout(() => {
  //         setSidebarDotPosition('calc(100vh + 100px)');
  //       }, 1100);
  //     } else {
  //       setSidebarDotPosition('calc(100vh + 100px)');
  //     }
  //   } else {
  //     setSidebarDotPosition(`calc(100vh - ${dotStartPosition}px)`);
  //   }
  //
  //   return () => clearTimeout(timeout);
  // }, [activeSection, deviceSize]);

  useEffect(() => {
    let consultationForm = document.getElementById('consultationFormId');
    let thirdSection = document.getElementById('thirdSection');
    let fourthSection = document.getElementById('fourthSection');
    let techStackContent = document.getElementById('techStackContentId');

    let timeout: ReturnType<typeof setTimeout>;
    if (fourthSectionOffset > (consultationForm?.offsetTop || 300)) {
      setSidebarDotPosition(
        `calc(${(consultationForm?.offsetTop || 0)
        + (fourthSection?.offsetTop || 0) + (consultationForm?.offsetHeight || 0)
        + 2}px`,
      );
    } else if (thirdSectionOffset > (techStackContent?.offsetTop || 300)) {
      setSidebarDotPosition(`calc(${(techStackContent?.offsetTop || 0) + (thirdSection?.offsetTop || 0)}px`);
    } else if (secondSectionOffset > 300) {
      if (prevActiveSection && prevActiveSection < activeSection) {
        setSidebarDotPosition('calc(100vh)');
        timeout = setTimeout(() => {
          setSidebarDotPosition('calc(100vh + 100px)');
        }, 1100);
      } else {
        setSidebarDotPosition('calc(100vh + 100px)');
      }
    } else {
      setSidebarDotPosition(`calc(100vh - ${dotStartPosition}px)`);
    }

    return () => clearTimeout(timeout);
  }, [activeSection, deviceSize]);

  return (
    <aside className={styles.sidebar}>
      <div
        className={styles.sidebar__dot}
        style={{
          top: sidebarDotPosition,
        }}
      />
      <div
        className={classnames(styles.sidebar__dot_trail, styles.sidebar__dot)}
        style={{
          top: sidebarDotPosition,
        }}
      />
      <div
        className={classnames(styles.sidebar__dot_trail2, styles.sidebar__dot)}
        style={{
          top: sidebarDotPosition,
        }}
      />
      <button
        aria-label='Toggle menu'
        type='button'
        onClick={() => setIsMenuOpened(!isMenuOpened)}
        className={classnames(styles.menuButton, {
          [styles.menuButton_active]: isMenuOpened,
        })}
      >
        <div className={styles.menuButton__line} />
        <div className={styles.menuButton__line} />
      </button>
    </aside>
  );
}

export default HomepageSidebar;
